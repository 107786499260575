<template>
  <div class="row">
    
    <div v-can="'add_staff'" class="col-xl-3 col-xxl-3 col-sm-6">
      <router-link :to="{name: 'Staff-Affairs-Staff'}">
        <div class="widget-stat card bg-white">
          <div class="card-body red-border-right">
            <div class="media">
              <span class="mr-3">
                <i class="la la-gear"></i>
              </span>
              <div class="media-body text-white">
                <h3 class="text-blue">Staff</h3>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

  </div>
</template>